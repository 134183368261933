.UserProfileGroupNames {
  @apply my-4;
  max-width: 280px;
}

.userProfileMenu {
  @apply !justify-start !text-black !p-2 mb-2;
}

.userProfileMenu:hover {
  @apply !bg-transparent;
}
