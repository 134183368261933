@tailwind base;
@tailwind components;
@tailwind utilities;

.desktop {
  --color-black: #000000;
  --color-white: #ffffff;
  --color-gray-0: #fafafa;
  --color-gray-5: #f5f5f5;
  --color-gray-10: #efefef;
  --color-gray-20: #d9dbdd;
  --color-gray-30: #c9cbcf;
  --color-gray-40: #b3b5b7;
  --color-gray-50: #808489;
  --color-gray-60: #65696c;
  --color-gray-70: #53575a;
  --color-gray-80: #45474a;
  --color-gray-90: #2f3031;
  --color-gray-100: #1e1f1f;
  --color-red-0: #fffafa;
  --color-red-5: #fef0f0;
  --color-red-10: #ffe6e7;
  --color-red-20: #facccf;
  --color-red-30: #fbb7bc;
  --color-red-40: #f7979f;
  --color-red-50: #ed5362;
  --color-red-60: #e02d38;
  --color-red-70: #c82228;
  --color-red-80: #af182c;
  --color-red-90: #70111d;
  --color-red-100: #3e0f15;
  --color-pink-0: #fef6fa;
  --color-pink-5: #feecf4;
  --color-pink-10: #ffe1ef;
  --color-pink-20: #fbc6e0;
  --color-pink-30: #fab2d6;
  --color-pink-40: #f692c6;
  --color-pink-50: #ed4a97;
  --color-pink-60: #d4307a;
  --color-pink-70: #c41b63;
  --color-pink-80: #b60c50;
  --color-pink-90: #6e0d37;
  --color-pink-100: #3e0e0e;
  --color-purple-0: #fbfaff;
  --color-purple-5: #f4f0fe;
  --color-purple-10: #ebe6ff;
  --color-purple-20: #ddd3fd;
  --color-purple-30: #cfc1fb;
  --color-purple-40: #bba7f6;
  --color-purple-50: #906cef;
  --color-purple-60: #7a47f0;
  --color-purple-70: #6b2ff9;
  --color-purple-80: #5723cf;
  --color-purple-90: #382166;
  --color-purple-100: #1e0f3d;
  --color-blue-0: #f6fafe;
  --color-blue-5: #ecf5fe;
  --color-blue-10: #e1effe;
  --color-blue-20: #cfe3fc;
  --color-blue-30: #b1d1fb;
  --color-blue-40: #91bcfd;
  --color-blue-50: #4c8df6;
  --color-blue-60: #2c70e5;
  --color-blue-70: #0b5fef;
  --color-blue-80: #054bcc;
  --color-blue-90: #103178;
  --color-blue-100: #0e1c39;
  --color-cyan-0: #f6fcfe;
  --color-cyan-5: #e7f6fe;
  --color-cyan-10: #dcf3fe;
  --color-cyan-20: #c0e8fc;
  --color-cyan-30: #9ddcfb;
  --color-cyan-40: #74cbfb;
  --color-cyan-50: #3996ed;
  --color-cyan-60: #1b7ada;
  --color-cyan-70: #0468cc;
  --color-cyan-80: #125096;
  --color-cyan-90: #103763;
  --color-cyan-100: #082126;
  --color-teal-0: #f1fefe;
  --color-teal-5: #defcfb;
  --color-teal-10: #d2f9f8;
  --color-teal-20: #b9eeee;
  --color-teal-30: #98e5e7;
  --color-teal-40: #6dd5d4;
  --color-teal-50: #479ca1;
  --color-teal-60: #347f7a;
  --color-teal-70: #1e716b;
  --color-teal-80: #165a57;
  --color-teal-90: #0d4241;
  --color-teal-100: #092524;
  --color-green-0: #f1fef5;
  --color-green-5: #e3fdeb;
  --color-green-10: #cefde0;
  --color-green-20: #bcf1d1;
  --color-green-30: #a1e8be;
  --color-green-40: #7bd3a1;
  --color-green-50: #4ca375;
  --color-green-60: #2e8a5c;
  --color-green-70: #117e47;
  --color-green-80: #04673a;
  --color-green-90: #084a2d;
  --color-green-100: #092519;
  --color-orange-5: #fff9ee;
  --color-orange-10: #ffeece;
  --color-orange-20: #ffd78a;
  --color-orange-30: #fabf4b;
  --color-orange-40: #eda62b;
  --color-yellow-5: #fffeee;
  --color-yellow-10: #fffdce;
  --color-yellow-20: #fff78a;
  --color-yellow-30: #faf44b;
  --color-yellow-40: #ede500;
  --color-action-default: #0b5fef;
  --color-action-hovered: #054bcc;
  --color-action-pressed: #103178;
  --color-action-disabled: #c9cbcf;
  --color-action-neutral-default: #2f3031;
  --color-action-neutral-hovered: #1e1f1f;
  --color-action-neutral-pressed: #000000;
  --color-background-default: #fafafa;
  --color-background-hovered: #f9fafa;
  /* Need to update */
  --color-background-pressed: #f9fafa;
  /* Need to update */
  --color-background-disabled: #f9fafa;
  /* Need to update */
  --color-background-expressive-dark-blue: #103178;
  --color-background-overlay-default: #000000;
  --color-primary: #0b5ae2;
  --color-critical: #c82236;
  --color-success: #35825a;
  --color-warning: #ffc453;
  --color-informational: #4c8df6;
  --color-border-high: #53575a;
  --color-border-default: #808489;
  --color-border-subdued: #c9cbcf;
  --color-border-disabled: #d9dbdd;
  --color-border-hovered: #45474a;
  --color-border-pressed: #909498;
  /* Need to update */
  --color-border-critical-default: #e02d38;
  --color-border-critical-subdued: #909498;
  /* Need to update */
  --color-border-critical-disabled: #909498;
  /* Need to update */
  --color-border-success-default: #909498;
  /* Need to update */
  --color-border-success-subdued: #909498;
  /* Need to update */
  --color-border-success-disabled: #909498;
  /* Need to update */
  --color-border-informational-default: #909498;
  /* Need to update */
  --color-border-informational-subdued: #909498;
  /* Need to update */
  --color-border-informational-disabled: #909498;
  /* Need to update */
  --color-icon-default: #45474a;
  --color-icon-subdued: #65696c;
  --color-icon-hovered: #2f3031;
  --color-icon-pressed: #909498;
  /* Need to update */
  --color-icon-disabled: #b3b5b7;
  --color-icon-critical: #e02d38;
  --color-icon-warning: #afb2b6;
  /* Need to update */
  --color-icon-success: #4ca375;
  --color-icon-informational: #054bcc;
  --color-icon-inverse: #f5f5f5;
  --color-interactive-default: #0b5fef;
  --color-interactive-pressed: #2c7fff;
  /* Need to update */
  --color-interactive-hovered: #054bcc;
  --color-interactive-disabled: #c9cbcf;
  --color-interactive-on-foreground: #91bcfd;
  --color-interactive-critical-default: #d93640;
  --color-interactive-critical-pressed: #2c7fff;
  /* Need to update */
  --color-interactive-critical-hovered: #2c7fff;
  /* Need to update */
  --color-interactive-focused: #4c8df6;
  --color-surface-default: #ffffff;
  --color-surface-subdued: #f5f5f5;
  --color-surface-hovered: #f5f5f5;
  --color-surface-pressed: #efefef;
  --color-surface-disabled: #efeff0;
  /* Need to update */
  --color-surface-foreground: #45474a;
  /* For snackbar */
  --color-surface-neutral-high: #efeff0;
  /* Need to update */
  --color-surface-neutral-default: #d9dbdd;
  --color-surface-neutral-subdued: #efefef;
  --color-surface-critical-default: #ffe9ea;
  --color-surface-critical-subdued: #fef4f4;
  --color-surface-critical-hovered: #efeff0;
  /* Need to update */
  --color-surface-critical-pressed: #efeff0;
  /* Need to update */
  --color-surface-success-default: #9ae5b9;
  --color-surface-success-subdued: #e3fdeb;
  --color-surface-success-hovered: #efeff0;
  /* Need to update */
  --color-surface-success-pressed: #efeff0;
  /* Need to update */
  --color-surface-informational-default: #98dafb;
  --color-surface-informational-subdued: #e7f6fe;
  --color-surface-informational-hovered: #efeff0;
  /* Need to update */
  --color-surface-informational-pressed: #efeff0;
  /* Need to update */
  --color-surface-warning-default: #ffdfa3;
  --color-surface-warning-subdued: #ffeece;
  --color-surface-warning-hovered: #efeff0;
  /* Need to update */
  --color-surface-warning-pressed: #efeff0;
  /* Need to update */
  --color-surface-selected-default: #ecf5fe;
  --color-surface-selected-hovered: #e1effe;
  --color-surface-selected-pressed: #efeff0;
  /* Need to update */
  --color-text-default: #2f3031;
  --color-text-subdued: #65696c;
  --color-text-disabled: #808489;
  --color-text-critical: #e02d38;
  --color-text-inverse: #ffffff;
  --color-text-inverse-subdued: #d9dbdd;
  --color-text-warning: #909498;
  /* Need to update */
  --color-text-success: #909498;
  /* Need to update */
  --color-text-informational: #909498;
  /* Need to update */
  --font-weight-helper: 400;
  --font-weight-helper-bold: 600;
  --font-weight-body-small: 400;
  --font-weight-body: 400;
  --font-weight-body-large: 400;
  --font-weight-action: 600;
  --font-weight-subheading: 600;
  --font-weight-heading-sm: 600;
  --font-weight-heading-md: 600;
  --font-weight-heading-lg: 600;
  --font-weight-display-sm: 600;
  --font-weight-display-md: 600;
  --font-weight-display-lg: 600;
  --font-weight-headline-xs: 600;
  --font-weight-headline-sm: 600;
  --font-weight-headline-md: 600;
  --font-weight-headline-lg: 600;
  --size-border-radius-default: 0.25rem;
  --size-border-radius-base: 0.25rem;
  --size-font-helper: 0.75rem;
  --size-font-helper-bold: 0.75rem;
  --size-font-body-small: 0.875rem;
  --size-font-body: 1rem;
  --size-font-body-large: 1.25rem;
  --size-font-action: 1rem;
  --size-font-subheading: 0.875rem;
  --size-font-heading-sm: 1.0625rem;
  --size-font-heading-md: 1.1875rem;
  --size-font-heading-lg: 1.375rem;
  --size-font-display-sm: 1.5rem;
  --size-font-display-md: 1.75rem;
  --size-font-display-lg: 2rem;
  --size-font-headline-xs: 2.625rem;
  --size-font-headline-sm: 3.375rem;
  --size-font-headline-md: 4rem;
  --size-font-headline-lg: 5rem;
  --size-line-height-text-helper: 1rem;
  --size-line-height-text-helper-bold: 1rem;
  --size-line-height-text-body-small: 1.25rem;
  --size-line-height-text-body: 1.5rem;
  --size-line-height-text-body-large: 1.75rem;
  --size-line-height-text-action: 1.5rem;
  --size-line-height-text-subheading: 1rem;
  --size-line-height-text-heading-sm: 1.375rem;
  --size-line-height-text-heading-md: 1.5rem;
  --size-line-height-text-heading-lg: 1.75rem;
  --size-line-height-text-display-sm: 1.75rem;
  --size-line-height-text-display-md: 2.25rem;
  --size-line-height-text-display-lg: 2.25rem;
  --size-line-height-text-headline-xs: 3rem;
  --size-line-height-text-headline-sm: 3.875rem;
  --size-line-height-text-headline-md: 4.5rem;
  --size-line-height-text-headline-lg: 5.5rem;
}
.mobile {
  --color-black: #000000;
  --color-white: #ffffff;
  --color-gray-0: #fafafa;
  --color-gray-5: #f5f5f5;
  --color-gray-10: #efefef;
  --color-gray-20: #d9dbdd;
  --color-gray-30: #c9cbcf;
  --color-gray-40: #b3b5b7;
  --color-gray-50: #808489;
  --color-gray-60: #65696c;
  --color-gray-70: #53575a;
  --color-gray-80: #45474a;
  --color-gray-90: #2f3031;
  --color-gray-100: #1e1f1f;
  --color-red-0: #fffafa;
  --color-red-5: #fef0f0;
  --color-red-10: #ffe6e7;
  --color-red-20: #facccf;
  --color-red-30: #fbb7bc;
  --color-red-40: #f7979f;
  --color-red-50: #ed5362;
  --color-red-60: #e02d38;
  --color-red-70: #c82228;
  --color-red-80: #af182c;
  --color-red-90: #70111d;
  --color-red-100: #3e0f15;
  --color-pink-0: #fef6fa;
  --color-pink-5: #feecf4;
  --color-pink-10: #ffe1ef;
  --color-pink-20: #fbc6e0;
  --color-pink-30: #fab2d6;
  --color-pink-40: #f692c6;
  --color-pink-50: #ed4a97;
  --color-pink-60: #d4307a;
  --color-pink-70: #c41b63;
  --color-pink-80: #b60c50;
  --color-pink-90: #6e0d37;
  --color-pink-100: #3e0e0e;
  --color-purple-0: #fbfaff;
  --color-purple-5: #f4f0fe;
  --color-purple-10: #ebe6ff;
  --color-purple-20: #ddd3fd;
  --color-purple-30: #cfc1fb;
  --color-purple-40: #bba7f6;
  --color-purple-50: #906cef;
  --color-purple-60: #7a47f0;
  --color-purple-70: #6b2ff9;
  --color-purple-80: #5723cf;
  --color-purple-90: #382166;
  --color-purple-100: #1e0f3d;
  --color-blue-0: #f6fafe;
  --color-blue-5: #ecf5fe;
  --color-blue-10: #e1effe;
  --color-blue-20: #cfe3fc;
  --color-blue-30: #b1d1fb;
  --color-blue-40: #91bcfd;
  --color-blue-50: #4c8df6;
  --color-blue-60: #2c70e5;
  --color-blue-70: #0b5fef;
  --color-blue-80: #054bcc;
  --color-blue-90: #103178;
  --color-blue-100: #0e1c39;
  --color-cyan-0: #f6fcfe;
  --color-cyan-5: #e7f6fe;
  --color-cyan-10: #dcf3fe;
  --color-cyan-20: #c0e8fc;
  --color-cyan-30: #9ddcfb;
  --color-cyan-40: #74cbfb;
  --color-cyan-50: #3996ed;
  --color-cyan-60: #1b7ada;
  --color-cyan-70: #0468cc;
  --color-cyan-80: #125096;
  --color-cyan-90: #103763;
  --color-cyan-100: #082126;
  --color-teal-0: #f1fefe;
  --color-teal-5: #defcfb;
  --color-teal-10: #d2f9f8;
  --color-teal-20: #b9eeee;
  --color-teal-30: #98e5e7;
  --color-teal-40: #6dd5d4;
  --color-teal-50: #479ca1;
  --color-teal-60: #347f7a;
  --color-teal-70: #1e716b;
  --color-teal-80: #165a57;
  --color-teal-90: #0d4241;
  --color-teal-100: #092524;
  --color-green-0: #f1fef5;
  --color-green-5: #e3fdeb;
  --color-green-10: #cefde0;
  --color-green-20: #bcf1d1;
  --color-green-30: #a1e8be;
  --color-green-40: #7bd3a1;
  --color-green-50: #4ca375;
  --color-green-60: #2e8a5c;
  --color-green-70: #117e47;
  --color-green-80: #04673a;
  --color-green-90: #084a2d;
  --color-green-100: #092519;
  --color-orange-5: #fff9ee;
  --color-orange-10: #ffeece;
  --color-orange-20: #ffd78a;
  --color-orange-30: #fabf4b;
  --color-orange-40: #eda62b;
  --color-yellow-5: #fffeee;
  --color-yellow-10: #fffdce;
  --color-yellow-20: #fff78a;
  --color-yellow-30: #faf44b;
  --color-yellow-40: #ede500;
  --color-action-default: #0b5fef;
  --color-action-hovered: #054bcc;
  --color-action-pressed: #103178;
  --color-action-disabled: #c9cbcf;
  --color-action-neutral-default: #2f3031;
  --color-action-neutral-hovered: #1e1f1f;
  --color-action-neutral-pressed: #000000;
  --color-background-default: #fafafa;
  --color-background-hovered: #f9fafa;
  /* Need to update */
  --color-background-pressed: #f9fafa;
  /* Need to update */
  --color-background-disabled: #f9fafa;
  /* Need to update */
  --color-background-expressive-dark-blue: #103178;
  --color-background-overlay-default: #000000;
  --color-primary: #0b5ae2;
  --color-critical: #c82236;
  --color-success: #35825a;
  --color-warning: #ffc453;
  --color-informational: #4c8df6;
  --color-border-high: #53575a;
  --color-border-default: #808489;
  --color-border-subdued: #c9cbcf;
  --color-border-disabled: #d9dbdd;
  --color-border-hovered: #45474a;
  --color-border-pressed: #909498;
  /* Need to update */
  --color-border-critical-default: #e02d38;
  --color-border-critical-subdued: #909498;
  /* Need to update */
  --color-border-critical-disabled: #909498;
  /* Need to update */
  --color-border-success-default: #909498;
  /* Need to update */
  --color-border-success-subdued: #909498;
  /* Need to update */
  --color-border-success-disabled: #909498;
  /* Need to update */
  --color-border-informational-default: #909498;
  /* Need to update */
  --color-border-informational-subdued: #909498;
  /* Need to update */
  --color-border-informational-disabled: #909498;
  /* Need to update */
  --color-icon-default: #45474a;
  --color-icon-subdued: #65696c;
  --color-icon-hovered: #2f3031;
  --color-icon-pressed: #909498;
  /* Need to update */
  --color-icon-disabled: #b3b5b7;
  --color-icon-critical: #e02d38;
  --color-icon-warning: #afb2b6;
  /* Need to update */
  --color-icon-success: #4ca375;
  --color-icon-informational: #054bcc;
  --color-icon-inverse: #f5f5f5;
  --color-interactive-default: #0b5fef;
  --color-interactive-pressed: #2c7fff;
  /* Need to update */
  --color-interactive-hovered: #054bcc;
  --color-interactive-disabled: #c9cbcf;
  --color-interactive-on-foreground: #91bcfd;
  --color-interactive-critical-default: #d93640;
  --color-interactive-critical-pressed: #2c7fff;
  /* Need to update */
  --color-interactive-critical-hovered: #2c7fff;
  /* Need to update */
  --color-interactive-focused: #4c8df6;
  --color-surface-default: #ffffff;
  --color-surface-subdued: #f5f5f5;
  --color-surface-hovered: #f5f5f5;
  --color-surface-pressed: #efefef;
  --color-surface-disabled: #efeff0;
  /* Need to update */
  --color-surface-foreground: #45474a;
  /* For snackbar */
  --color-surface-neutral-high: #efeff0;
  /* Need to update */
  --color-surface-neutral-default: #d9dbdd;
  --color-surface-neutral-subdued: #efefef;
  --color-surface-critical-default: #ffe9ea;
  --color-surface-critical-subdued: #fef4f4;
  --color-surface-critical-hovered: #efeff0;
  /* Need to update */
  --color-surface-critical-pressed: #efeff0;
  /* Need to update */
  --color-surface-success-default: #9ae5b9;
  --color-surface-success-subdued: #e3fdeb;
  --color-surface-success-hovered: #efeff0;
  /* Need to update */
  --color-surface-success-pressed: #efeff0;
  /* Need to update */
  --color-surface-informational-default: #98dafb;
  --color-surface-informational-subdued: #e7f6fe;
  --color-surface-informational-hovered: #efeff0;
  /* Need to update */
  --color-surface-informational-pressed: #efeff0;
  /* Need to update */
  --color-surface-warning-default: #ffdfa3;
  --color-surface-warning-subdued: #ffeece;
  --color-surface-warning-hovered: #efeff0;
  /* Need to update */
  --color-surface-warning-pressed: #efeff0;
  /* Need to update */
  --color-surface-selected-default: #ecf5fe;
  --color-surface-selected-hovered: #e1effe;
  --color-surface-selected-pressed: #efeff0;
  /* Need to update */
  --color-text-default: #2f3031;
  --color-text-subdued: #65696c;
  --color-text-disabled: #808489;
  --color-text-critical: #e02d38;
  --color-text-inverse: #ffffff;
  --color-text-inverse-subdued: #d9dbdd;
  --color-text-warning: #909498;
  /* Need to update */
  --color-text-success: #909498;
  /* Need to update */
  --color-text-informational: #909498;
  /* Need to update */
  --font-weight-helper: 400;
  --font-weight-helper-bold: 600;
  --font-weight-body-small: 400;
  --font-weight-body: 400;
  --font-weight-action: 600;
  --font-weight-subheading: 600;
  --font-weight-heading-sm: 600;
  --font-weight-heading-md: 600;
  --font-weight-heading-lg: 600;
  --font-weight-eyebrow-sm: 400;
  --font-weight-eyebrow-md: 400;
  --font-weight-eyebrow-lg: 400;
  --font-weight-display-sm: 600;
  --font-weight-display-md: 600;
  --font-weight-display-lg: 600;
  --size-border-radius-default: 0.25rem;
  --size-border-radius-base: 0.25rem;
  --size-font-helper: 0.75rem;
  --size-font-helper-bold: 0.75rem;
  --size-font-body-small: 0.8125rem;
  --size-font-body: 0.9375rem;
  --size-font-action: 0.9375rem;
  --size-font-subheading: 0.8125rem;
  --size-font-heading-sm: 1rem;
  --size-font-heading-md: 1.125rem;
  --size-font-heading-lg: 1.375rem;
  --size-font-eyebrow-sm: 0.75rem;
  --size-font-eyebrow-md: 0.9375rem;
  --size-font-eyebrow-lg: 1.0625rem;
  --size-font-display-sm: 1.5rem;
  --size-font-display-md: 1.75rem;
  --size-font-display-lg: 2rem;
  --size-line-height-text-helper: 1rem;
  --size-line-height-text-helper-bold: 1rem;
  --size-line-height-text-body-small: 1.25rem;
  --size-line-height-text-body: 1.375rem;
  --size-line-height-text-action: 1.5rem;
  --size-line-height-text-subheading: 1rem;
  --size-line-height-text-heading-sm: 1.375rem;
  --size-line-height-text-heading-md: 1.5rem;
  --size-line-height-text-heading-lg: 1.75rem;
  --size-line-height-text-eyebrow-sm: 1rem;
  --size-line-height-text-eyebrow-md: 1.5rem;
  --size-line-height-text-eyebrow-lg: 1.5rem;
  --size-line-height-text-display-sm: 1.75rem;
  --size-line-height-text-display-md: 2.25rem;
  --size-line-height-text-display-lg: 2.25rem;
}
.container {
  width: 100vw;
  height: 100vh;
  font-size: 1.2rem;
}

code {
  font-style: italic;
  color: cornflowerblue;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #1ac7f1;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #1ac7f1, 0 0 5px #1ac7f1;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #1ac7f1;
  border-left-color: #1ac7f1;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bg-surface-neutral-subdued {
  background: #efefef;
}

.dot-gray,
.dot-red,
.dot-green,
.dot-blue,
.dot-orange,
.dot-yellow,
.dot-black {
  width: 8px;
  height: 8px;
  background: #45474a;
  margin-right: 8px;
  border-radius: 8px;
}

.dot-red {
  background: #e02d38 !important;
}

.dot-green {
  background: #4ca375 !important;
}

.dot-blue {
  background: #4c8df6 !important;
}

.dot-orange {
  background: #eda62b !important;
}

.dot-black {
  background: #45474a !important;
}

.dot-yellow {
  background: #ffc453 !important;
}

/* Combo Box */
.combo-box-group .combo-box:first-child .combo-box__control {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.combo-box-group .combo-box:last-child .combo-box__control {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.combo-box-group
  .combo-box:not(:first-child):not(:last-child)
  .combo-box__control {
  border-radius: 0;
}

/* Rich Text Editor */
.rich-text-editor {
  border-radius: 0.25rem;
}

.rich-text-editor .ql-formats {
  padding: 0 15px;
  margin: 0 !important;
  gap: 2px;
}
.rich-text-editor .ql-formats:first-child {
  padding: 0 15px 0 0;
}

.rich-text-editor .ql-formats:not(:first-child) {
  border-left: 1px solid var(--color-border-critical-disabled);
}

.rich-text-editor button {
  border-radius: 5px;
}

.rich-text-editor button[type='button']:active,
.rich-text-editor button[type='button'].ql-active:active {
  background-color: var(--color-gray-50) !important;
}

.rich-text-editor button:hover,
.rich-text-editor button.ql-active:hover {
  background-color: var(--color-surface-neutral-default) !important;
}

.rich-text-editor button:hover .ql-stroke {
  stroke: black !important;
}

.rich-text-editor button.ql-active {
  background-color: var(--color-gray-30) !important;
}

.rich-text-editor button.ql-active .ql-stroke {
  stroke: black !important;
}

.rich-text-editor .ql-toolbar {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-color: #808489 !important;
  background-color: var(--color-surface-subdued);
}

.rich-text-editor .ql-container {
  min-height: 100px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-color: #808489 !important;
  border-top-color: transparent !important;
}

.rich-text-editor .ql-editor {
  font-size: 15px;
  line-height: 22px;
  min-height: 100px;
}

.rich-text-editor:focus {
  box-shadow: 0 0 0 2px #4c8df6 !important;
  border-color: #4c8df6 !important;
}

.rich-text-editor.is-error {
  background-color: #fef4f4 !important;
  border-color: #e02d38 !important;
}

.rich-text-editor.is-error .ql-toolbar {
  border-color: #e02d38 !important;
}

.rich-text-editor.is-error .ql-container {
  border-color: #e02d38 !important;
  border-top-color: transparent !important;
}

.react-datepicker__triangle {
  transform: translate(120px, 0px) !important;
}

.toolkit-report-feedback-container ol {
  counter-reset: lis 0, alp;
  margin-left: 20px;
}

.toolkit-report-feedback-container ol li:not(.ql-indent-1) {
  counter-increment: lis;
  counter-reset: alp;
}

.toolkit-report-feedback-container ol li:not(.ql-indent-1):before {
  content: counter(lis) '. ';
}

.toolkit-report-feedback-container .ql-indent-1 {
  margin-left: 40px;
}

.toolkit-report-feedback-container ol li.ql-indent-1 {
  counter-increment: alp;
}

.toolkit-report-feedback-container ol li.ql-indent-1:before {
  content: counter(alp, lower-alpha) '. ';
}
