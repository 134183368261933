.SidebarLink {
  @apply flex w-full text-center cursor-pointer px-3 py-2 font-medium rounded-md outline-none hover:text-default hover:bg-gray-10 mt-1;
}

.SidebarLink:nth-child(1) {
  @apply mt-0;
}

.SidebarLink:focus {
  outline-color: var(--color-interactive-default);
}

.SidebarLinkActive,
.SidebarLinkActive path,
.SidebarLinkActive:hover {
  @apply bg-surface-neutral-subdued text-interactive-default !important;
  fill: rgba(11, 95, 239, var(--tw-text-opacity));
}

.SidebarLinkActive > *,
.SidebarLinkActive:hover > * {
  @apply text-interactive-default !important;
}
